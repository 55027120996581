import React from 'react';
import discord from '../images/chate.png';
import warpcast from '../images/warpcast.jpg';
import github from '../images/git.png';
const Footer = () => {
    return (React.createElement("footer", { className: "footerdown footer fixed-bottom" },
        React.createElement("div", { className: "globlefooter" },
            React.createElement("div", { className: "footercol" },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://discord.gg/WvcEAcg9Aj", target: "_blank" },
                            React.createElement("img", { src: discord, title: "chat", alt: "chat" }))),
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://warpcast.com/kodys.eth", target: "_blank" },
                            React.createElement("img", { style: { width: "30px" }, src: warpcast, title: "Warpcast", alt: "Warpcast" }))),
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://github.com/Smoothly-Protocol", target: "_blank" },
                            React.createElement("img", { src: github, title: "Github", alt: "Github" }))))),
            React.createElement("div", { className: "footercol" },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://docs.smoothly.money/frequently-asked-questions", target: "_blank" }, "FAQ")),
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://docs.smoothly.money/", target: "_blank" }, "Docs")),
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://docs.smoothly.money/how-to-guide", target: "_blank" }, "Guide")))))));
};
export default Footer;
