var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { utils } from 'ethers';
import { useSigner, useAccount } from 'wagmi';
import { useContract } from '../utils/constants';
import { OverlayTrigger, Popover, Modal, Button } from 'react-bootstrap';
import { statusBadgeColor } from '../utils/badgeColors';
import { status, standing } from '../utils/standing';
import formatEthAmount from "../utils/formatEthAmount";
import { HashLoader } from 'react-spinners';
import { getProofArgs } from '../utils/helpers';
const Claim = ({ validators, refreshData }) => {
    const { data: signer } = useSigner();
    const { address } = useAccount();
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const claim = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const contract = useContract(signer);
            const { proof } = yield getProofArgs(address, "withdrawals");
            const tx = yield contract.withdrawRewards(proof[0], proof[1], proof[2]);
            yield tx.wait();
            handleModalShow("Success", "Rewards claimed successfully.");
        }
        catch (err) {
            handleModalShow("Error", "No rewards are able to be claimed. Make sure you have unclaimed rewards before attempting to claim.");
        }
        refreshData();
        setLoading(false);
    });
    const handleModalClose = () => {
        setShowModal(false);
        setModalTitle("");
        setModalMessage("");
    };
    const handleModalShow = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    const standingPopover = (standing) => {
        let message = "";
        if (standing === "Okay") {
            message = "Verify your validator configuration, you've missed a proposal";
        }
        else if (standing === "Bad") {
            message = `You've missed another proposal, 0.015 ETH has been taken from your insurance deposit and added to the pool. 
                  Top up your Insurance to be included in future rebalances.`;
        }
        else if (standing === "Forced Exit") {
            message = `You proposed a block with the wrong fee recipient, 0.5 ETH has been taken from your insurance deposit and 
                  you have been removed from the pool index`;
        }
        else if (standing === "N/A") {
            message = "Only Active validators can claim rewards, awaiting block proposal";
        }
        return (React.createElement(Popover, { id: "popover-basic" },
            React.createElement(Popover.Body, null, message)));
    };
    const isActivated = (validator) => {
        return status(validator) === "Awaiting Activation" ? true : false;
    };
    return (React.createElement("div", { className: "tab-pane", id: "tabs-3", role: "tabpanel" },
        React.createElement("div", { className: "fullhegigth" },
            React.createElement("h2", null, "Registered Validators"),
            React.createElement("div", { className: "mcrow" },
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "text-center" }, "Validator index"),
                            React.createElement("th", { className: "text-center" }, "Unclaimed Rewards"),
                            React.createElement("th", { className: "text-center" }, "Status"),
                            React.createElement("th", { className: "text-center" }, "Claim"))),
                    React.createElement("tbody", null, validators
                        .map((validator, key) => (React.createElement("tr", { key: key },
                        React.createElement("td", { className: 'text-center' }, `${validator.index}`),
                        React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(validator.rewards))),
                        React.createElement("td", { className: "text-center" },
                            React.createElement("span", { className: `badge ${statusBadgeColor(status(validator))} text-light` }, status(validator))),
                        React.createElement("td", { className: "text-center" }, isActivated(validator) && (React.createElement(OverlayTrigger, { trigger: ["hover", "focus"], placement: "right", overlay: standingPopover(standing(validator)) },
                            React.createElement("span", { className: "cursor-pointer" }, "\u2753")))))))))),
            loading ?
                (React.createElement("div", { className: "d-flex flex-row fixebtn justify-content-center" },
                    React.createElement(HashLoader, { color: '#bc519a', loading: loading, size: 50, "aria-label": "Loading Spinner", "data-testid": "loader" })))
                :
                    (React.createElement("div", { className: "fixebtn" },
                        React.createElement("a", { href: "#", onClick: claim, className: "uniqbtn" }, "Claim Rewards")))),
        React.createElement(Modal, { show: showModal, onHide: handleModalClose, centered: true },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, modalTitle)),
            React.createElement(Modal.Body, null, modalMessage),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { onClick: handleModalClose }, "Close")))));
};
export default Claim;
