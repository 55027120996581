import React from 'react';
import { createRoot } from 'react-dom/client';
import { getDefaultWallets, RainbowKitProvider, } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { goerli } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import App from './App';
import "./css/style.css";
import "./css/bootstrap.min.css";
import "./css/font-awesome.min.css";
import './css/rainbowkit.css';
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
const container = document.getElementById('root');
const root = createRoot(container);
const { chains, provider } = configureChains([goerli], [
    publicProvider()
]);
const { connectors } = getDefaultWallets({
    appName: 'Smoothly Protocol',
    chains
});
const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
});
root.render(React.createElement(WagmiConfig, { client: wagmiClient },
    React.createElement(RainbowKitProvider, { chains: chains },
        React.createElement(App, null))));
