import React, { useState } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import sun from '../images/sun.png';
import logoNew from "../images/web_logo_cropped.png";
const Header = () => {
    const toggleBg = () => {
        let b = document.getElementById("bgcolorchange");
        if (b.className === "") {
            b.className = "black-background";
            document.body.style.backgroundColor = "black";
        }
        else {
            b.className = "";
            document.body.style.backgroundColor = "white";
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement("header", { className: "nav-bar" },
        React.createElement("div", { className: "mobile-menu" },
            React.createElement("div", { id: "hamburger", onClick: () => setIsOpen(!isOpen) },
                React.createElement("i", { className: `fa fa-bars fa-2x ${isOpen ? 'open' : ''}` })),
            React.createElement("div", { className: "mobile-container", style: { opacity: (isOpen ? '1' : '0') } },
                React.createElement("ul", { className: "nav", role: "tablist", onClick: () => setIsOpen(!isOpen) },
                    React.createElement("li", null,
                        React.createElement("a", { className: "nav-link active", "data-toggle": "tab", href: "#tabs-1", role: "tab" }, "Register Validator")),
                    React.createElement("li", null,
                        React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-2", role: "tab" }, "Check Balance")),
                    React.createElement("li", null,
                        React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-3", role: "tab" }, "Claim Rewards")),
                    React.createElement("li", null,
                        React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-4", role: "tab" }, "Exit Pool")),
                    React.createElement("li", null,
                        React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-5", role: "tab" }, "Pool Stats")))),
            React.createElement("div", { className: "non-menu-buttons" },
                React.createElement(ConnectButton, null))),
        React.createElement("div", { className: "globleheader" },
            React.createElement("div", { className: "headercol d-flex align-baseline" },
                React.createElement("img", { src: logoNew, className: "header-logo", title: "Smoothly MEV Smoothing Pool", alt: "Smoothly MEV Smoothing Pool" }),
                React.createElement("div", { className: "menubar ml-4" },
                    React.createElement("ul", { className: "nav", role: "tablist" },
                        React.createElement("li", null,
                            React.createElement("a", { className: "nav-link active", "data-toggle": "tab", href: "#tabs-1", role: "tab" }, "Register Validator")),
                        React.createElement("li", null,
                            React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-2", role: "tab" }, "Check Balance")),
                        React.createElement("li", null,
                            React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-3", role: "tab" }, "Claim Rewards")),
                        React.createElement("li", null,
                            React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-4", role: "tab" }, "Exit Pool")),
                        React.createElement("li", null,
                            React.createElement("a", { className: "nav-link", "data-toggle": "tab", href: "#tabs-5", role: "tab" }, "Pool Stats"))))),
            React.createElement("div", { className: "headercol" },
                React.createElement("ul", { className: "inventory-items" },
                    React.createElement("li", null,
                        React.createElement("button", { id: "sellButton", onClick: toggleBg },
                            React.createElement("i", { className: "fa fa-moon-o", "aria-hidden": "true" }),
                            React.createElement("img", { src: sun, className: "darkmode" }))),
                    React.createElement(ConnectButton, { chainStatus: "none" }))))));
};
export default Header;
