import React from 'react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import logo from '../images/smoothly_web_text.png';
import discord from '../images/chate.png';
import warpcast from '../images/warpcast.jpg';
import github from '../images/git.png';
import docs from '../images/docs.png';
const Landing = () => {
    const { openConnectModal } = useConnectModal();
    return (React.createElement("div", { className: "fullbodybg" },
        React.createElement("div", { className: "logostyle" },
            React.createElement("p", null,
                React.createElement("img", { style: { width: 500 }, src: logo, title: "MEV Smoothing Pool", alt: "MEV Smoothing Pool" })),
            React.createElement("p", { className: "esbtnwidth" },
                React.createElement("button", { onClick: openConnectModal, className: "entersmoothly", type: "button" },
                    "Enter Smoothly",
                    React.createElement("i", { className: "fa fa-angle-right", "aria-hidden": "true" })))),
        React.createElement("div", { className: "splashfooter d-flex align-items-center" },
            React.createElement("div", { className: "splashcol" },
                React.createElement("p", null,
                    React.createElement("a", { href: "https://discord.gg/WvcEAcg9Aj", target: "_blank" },
                        React.createElement("img", { src: discord, title: "discord", alt: "discord" })))),
            React.createElement("div", { className: "splashcol" },
                React.createElement("p", null,
                    React.createElement("a", { href: "https://warpcast.com/kodys.eth", target: "_blank" },
                        React.createElement("img", { style: { width: "30px" }, src: warpcast, title: "Warpcast", alt: "Warpcast" })))),
            React.createElement("div", { className: "splashcol" },
                React.createElement("p", null,
                    React.createElement("a", { href: "https://twitter.com/0xSmoothly", target: "_blank" },
                        React.createElement("img", { src: github, title: "Github", alt: "Github" })))),
            React.createElement("div", { className: "splashcol" },
                React.createElement("p", null,
                    React.createElement("a", { href: "https://docs.smoothly.money", target: "_blank" },
                        React.createElement("img", { src: docs, title: "Docs", alt: "Docs" })))))));
};
export default Landing;
