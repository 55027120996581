var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { utils } from "ethers";
import { useSigner } from 'wagmi';
import { useContract, STAKE_FEE } from '../utils/constants';
import { standing, status } from '../utils/standing';
import { statusBadgeColor, standingBadgeColor } from '../utils/badgeColors';
import formatEthAmount from "../utils/formatEthAmount";
import { OverlayTrigger, Popover, Button, Modal } from 'react-bootstrap';
import { HashLoader } from 'react-spinners';
const Balance = ({ validators, refreshData }) => {
    const { data: signer } = useSigner();
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const handleModalClose = () => {
        setShowModal(false);
    };
    const handleModalShow = (title, message) => {
        setModalMessage(message);
        setModalTitle(title);
        setShowModal(true);
    };
    const addStake = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        let added = 0;
        try {
            for (let i = 0; i < validators.length; i++) {
                if (STAKE_FEE.gt(validators[i].stake) && !validators[i].deactivated) {
                    const contract = useContract(signer);
                    const amount = STAKE_FEE.sub(validators[i].stake);
                    const tx = yield contract.addStake(validators[i].index, { value: amount });
                    yield tx.wait();
                    added++;
                    handleModalShow("Success", "Your stake was added to the pool!");
                }
            }
            added === 0 ? handleModalShow("Info", "Your stake position is good, no more stake is needed") : 0;
            setLoading(false);
        }
        catch (err) {
            handleModalShow("Error", "Something went wrong. Your stake was not added to the pool.");
            console.log(err);
        }
        refreshData();
        setLoading(false);
    });
    const daysTillRebalance = () => {
        const d = new Date;
        const day = d.getDay();
        if (day > 0) {
            return String(7 - day);
        }
        return "0";
    };
    const popover = (React.createElement(Popover, { id: "popover-basic" },
        React.createElement(Popover.Body, null, "You aren't able to withdraw until activated.")));
    const standingPopover = (standing) => {
        let message;
        if (standing === "Okay") {
            message = "Verify your validator configuration, you've missed a proposal";
        }
        else if (standing === "Bad") {
            message = `You've missed another proposal, 0.015 ETH has been taken from your insurance deposit and added to the pool. 
        Top up your Insurance to be included in future rebalances.`;
        }
        else if (standing === "Forced Exit") {
            message = `You proposed a block with the wrong fee recipient, 0.5 ETH has been taken from your insurance deposit and 
        you have been removed from the pool index`;
        }
        else {
            message = "Only Active validators are given a standing, awaiting block proposal";
        }
        return (React.createElement(Popover, { id: "popover-basic" },
            React.createElement(Popover.Body, null, message)));
    };
    return (React.createElement("div", { className: "tab-pane balance-page", id: "tabs-2", role: "tabpanel" },
        React.createElement("h2", null, "Rewards"),
        React.createElement("div", { className: "mcrow" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "text-center" }, "Validator Index"),
                        React.createElement("th", { className: "text-center" }, "Unclaimed Rewards"),
                        React.createElement("th", { className: "text-center" }, "Claimed Rewards"),
                        React.createElement("th", { className: "text-center" }, "Days till Rebalance"),
                        React.createElement("th", { className: "text-center" }, "Status"))),
                React.createElement("tbody", null, validators.map((validator, key) => (React.createElement("tr", { key: key },
                    React.createElement("td", { className: 'text-center' }, `${validator.index}`),
                    React.createElement("td", { className: `text-center ${status(validator) !== "Awaiting Activation" ? '' : 'pl-ch'}` },
                        formatEthAmount(utils.formatEther(validator.rewards)),
                        status(validator) !== "Awaiting Activation" ? null : (React.createElement(OverlayTrigger, { trigger: ["hover", "focus"], placement: "right", overlay: popover },
                            React.createElement("span", null, React.createElement("span", { className: "cursor-pointer" }, "\u2757\uFE0F"))))),
                    React.createElement("td", { className: "text-center" }, 0 /*withdrawals*/),
                    React.createElement("td", { className: "text-center" }, `${daysTillRebalance()}`),
                    React.createElement("td", { className: "text-center" },
                        React.createElement("span", { className: `badge ${statusBadgeColor(status(validator))} text-light` }, status(validator))))))))),
        React.createElement("h2", { className: "margins2" }, "Penalties"),
        React.createElement("div", { className: "mcrow margins1" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "text-center" }, "Public Key"),
                        React.createElement("th", { className: "text-center" }, "Deposit Balance"),
                        React.createElement("th", { className: "text-center" }, "Penalties"),
                        React.createElement("th", { className: "text-center" }, "Status"),
                        React.createElement("th", { className: "text-center" }, "Standing"))),
                React.createElement("tbody", null, validators.map((validator, key) => (React.createElement("tr", { key: key },
                    React.createElement("td", { className: 'text-center' }, `${validator.index}`),
                    React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(validator.stake))),
                    React.createElement("td", { className: "text-center" }, validator.slashFee + validator.slashMiss),
                    React.createElement("td", { className: "text-center" },
                        React.createElement("span", { className: `badge ${statusBadgeColor(status(validator))} text-light` }, status(validator))),
                    React.createElement("td", { className: "text-center" },
                        React.createElement("span", { className: `badge ${standingBadgeColor(standing(validator))} text-light` },
                            standing(validator),
                            ' ',
                            standing(validator) !== "All Good" &&
                                React.createElement(OverlayTrigger, { trigger: ["hover", "focus"], placement: "right", overlay: standingPopover(standing(validator)) },
                                    React.createElement("span", { className: "text-light" },
                                        React.createElement("i", { className: "fa fa-info-circle fa-md", "aria-hidden": "true" }))))))))))),
        loading ?
            (React.createElement("div", { className: "d-flex flex-row fixebtn justify-content-center" },
                React.createElement(HashLoader, { color: '#bc519a', loading: loading, size: 50, "aria-label": "Loading Spinner", "data-testid": "loader" })))
            :
                (React.createElement("div", { className: "fixebtn", onClick: addStake },
                    React.createElement("a", { href: "#", className: "uniqbtn" }, "Top Up Deposit"))),
        React.createElement(Modal, { show: showModal, onHide: handleModalClose, centered: true },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, modalTitle)),
            React.createElement(Modal.Body, null, modalMessage),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { onClick: handleModalClose }, "Close")))));
};
export default Balance;
