var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { utils } from "ethers";
import { useSigner } from 'wagmi';
import { contractAddress, STAKE_FEE } from '../utils/constants';
import { standing, status } from '../utils/standing';
import { useContract } from '../utils/constants';
import { statusBadgeColor, standingBadgeColor } from '../utils/badgeColors';
import { HashLoader } from 'react-spinners';
import { OverlayTrigger, Popover, Modal, Button } from 'react-bootstrap';
const Register = ({ validators, registrants, refreshData }) => {
    const { data: signer } = useSigner();
    const [selectedValidators, setSelectedValidators] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const handleModalClose = () => {
        setShowModal(false);
        setLoading(false);
    };
    const handleModalShow = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    const updateSelectedValidators = () => {
        const arg = [];
        const index = document.getElementsByClassName("index");
        for (let i = 0; i < index.length; i++) {
            if (index[i].checked) {
                arg.push(index[i].value);
            }
        }
        setSelectedValidators(arg);
    };
    const register = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const contract = useContract(signer);
            const stake = document.getElementById("stakeInfo");
            const boost = document.getElementById("boostInfo");
            const feeRecipient = document.getElementById("feeRecipient");
            if (stake.checked && feeRecipient.checked && boost) {
                if (selectedValidators.length > 0) {
                    const tx = yield contract.registerBulk(selectedValidators, {
                        value: STAKE_FEE.mul(selectedValidators.length)
                    });
                    yield tx.wait();
                    handleModalShow("Success", "Registered validators on contract, this can take up to 20 min to verify, please don't reregister the validators again. Come back later to check them out!");
                }
                else {
                    handleModalShow("Error", "No validators selected");
                }
            }
            else {
                handleModalShow("Info", "Please make sure you are aware of all our guidelines first");
            }
        }
        catch (err) {
            console.log(err);
        }
        setLoading(false);
        refreshData();
    });
    const standingPopover = (standing) => {
        let message;
        if (standing === "Okay") {
            message = "Verify your validator configuration, you've missed a proposal";
        }
        else if (standing === "Bad") {
            message = `You've missed another proposal, 0.15 ETH has been taken from your insurance deposit and added to the pool. 
                  Top up your Insurance to be included in future rebalances.`;
        }
        else if (standing === "Forced Exit") {
            message = `You proposed a block with the wrong fee recipient, 0.65 ETH has been taken from your insurance deposit and 
                  you have been removed from the pool index`;
        }
        else {
            message = "Only Active validators are given a standing, awaiting block proposal";
        }
        return (React.createElement(Popover, { id: "popover-basic" },
            React.createElement(Popover.Body, null, message)));
    };
    return (React.createElement("div", { className: "tab-pane active", id: "tabs-1", role: "tabpanel" },
        (registrants.length + validators.length === 0) &&
            React.createElement(React.Fragment, null,
                React.createElement("h2", null, "No Validators detected to account")),
        validators.length > 0 &&
            React.createElement(React.Fragment, null,
                React.createElement("h2", null, "Registered Validators"),
                React.createElement("div", { className: "mcrow" },
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { className: "text-center" }, "Validator Index"),
                                React.createElement("th", { className: "text-center" }, "Status"),
                                React.createElement("th", { className: "text-center" }, "Standing"))),
                        React.createElement("tbody", null, validators.map((validator, key) => (React.createElement(React.Fragment, null, (validator.active || validator.exitRequested) &&
                            React.createElement("tr", { key: key },
                                React.createElement("td", { className: 'text-center' }, `${validator.index}`),
                                React.createElement("td", { className: "text-center" },
                                    React.createElement("span", { className: `badge ${statusBadgeColor(status(validator))} text-light` }, status(validator))),
                                React.createElement("td", { className: "text-center" },
                                    React.createElement("span", { className: `badge ${standingBadgeColor(standing(validator))} text-light` },
                                        standing(validator),
                                        ' ',
                                        standing(validator) !== "All Good" &&
                                            React.createElement(OverlayTrigger, { trigger: ["hover", "focus"], placement: "right", overlay: standingPopover(standing(validator)) },
                                                React.createElement("span", { className: "text-light" },
                                                    React.createElement("i", { className: "fa fa-info-circle fa-md", "aria-hidden": "true" }))))))))))))),
        (registrants.length > 0 || selectedValidators.length > 0) && (React.createElement("div", null,
            registrants.length > 0 &&
                React.createElement(React.Fragment, null,
                    React.createElement("h2", { className: "margins1" }, "Unregistered Validators"),
                    React.createElement("div", { className: "mcrow margins1" },
                        React.createElement("table", null,
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { className: "text-center" }, "Validator index"),
                                    React.createElement("th", { className: "text-center" }, "Status"),
                                    React.createElement("th", { className: "text-center" }, "Join the Pool!"))),
                            React.createElement("tbody", null, registrants.map((validator, key) => (React.createElement("tr", { key: key },
                                React.createElement("td", { className: 'text-center' }, `${validator.index}`),
                                React.createElement("td", { className: "text-center" },
                                    React.createElement("span", { className: "badge badge-secondary text-light" }, "Inactive")),
                                React.createElement("td", { className: "text-center" },
                                    React.createElement("form", null,
                                        React.createElement("input", { type: "checkbox", className: "index", onChange: updateSelectedValidators, value: validator.index })))))))))),
            React.createElement("div", { className: "mcrow1 margins1" },
                React.createElement("div", { className: "form-group form-check" },
                    React.createElement("input", { type: "checkbox", className: "form-check-input", id: "stakeInfo" }),
                    React.createElement("label", { className: "form-check-label" },
                        "I've read the Smoothly ",
                        React.createElement("a", { href: "https://0xsmoothly.notion.site/" }, "Documentation"),
                        " and understand how the pool functions")),
                React.createElement("div", { className: "form-group form-check" },
                    React.createElement("input", { type: "checkbox", className: "form-check-input", id: "boostInfo" }),
                    React.createElement("label", { className: "form-check-label" }, "Running MEV Boost and subscribing to one or more of the approved relays is required.")),
                React.createElement("div", { className: "form-group form-check" },
                    React.createElement("input", { type: "checkbox", className: "form-check-input", id: "feeRecipient" }),
                    React.createElement("label", { className: "form-check-label" },
                        React.createElement("span", { className: "d-flex align-middle gap-2" },
                            "Please verify the fee recipient is ",
                            contractAddress,
                            React.createElement("i", { onClick: () => navigator.clipboard.writeText(contractAddress), className: "copy-button fa fa-clone fa-lg" }))))))),
        (registrants.length > 0 || selectedValidators.length > 0) && (loading ?
            (React.createElement("div", { className: "d-flex flex-row fixebtn justify-content-center" },
                React.createElement(HashLoader, { color: '#bc519a', loading: loading, size: 50, "aria-label": "Loading Spinner", "data-testid": "loader" })))
            :
                (React.createElement("div", { className: "fixebtn" },
                    React.createElement("a", { href: "#", onClick: register, className: "uniqbtn" },
                        "Deposit ",
                        utils.formatEther(STAKE_FEE.mul(selectedValidators.length)),
                        " ETH & Register")))),
        React.createElement(Modal, { show: showModal, onHide: handleModalClose, centered: true },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, modalTitle)),
            React.createElement(Modal.Body, null, modalMessage),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { onClick: handleModalClose }, "Close")))));
};
export default Register;
