var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { useSigner, useAccount } from 'wagmi';
import { useContract } from '../utils/constants';
import { HashLoader } from 'react-spinners';
import { Modal, Button } from 'react-bootstrap';
import { getProofArgs } from '../utils/helpers';
const Exit = ({ validators, refreshData }) => {
    const { data: signer } = useSigner();
    const { address } = useAccount();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [allowedExit, setAllowedExit] = useState(undefined);
    const [proof, setProof] = useState([]);
    const handleModalClose = () => {
        setShowModal(false);
    };
    const handleModalShow = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    const exit = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Handle 0 selected 
            if (proof.length < 1) {
                setLoading(false);
                return handleModalShow("Error", "No Validators allowed to exit");
            }
            const contract = useContract(signer);
            const tx = yield contract.withdrawStake(proof[0], proof[1], proof[2]);
            yield tx.wait();
            setLoading(false);
            handleModalShow("Success", "Exited protocol for selected validators");
        }
        catch (err) {
            handleModalShow("Error", "There was an error exiting protocol for selected validators");
        }
    });
    const reqExit = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            let input = document.getElementsByClassName("validator-exit");
            let arg = [];
            // Get selected Validators
            for (let i = 0; i < input.length; i++) {
                if (input[i].checked) {
                    arg.push(Number(input[i].value));
                }
            }
            // Handle 0 selected 
            if (arg.length < 1) {
                setLoading(false);
                return handleModalShow("Error", "No validators selected");
            }
            const contract = useContract(signer);
            const tx = yield contract.requestExit(arg);
            yield tx.wait();
            setLoading(false);
            handleModalShow("Success", "Exited protocol for selected validators");
        }
        catch (err) {
            handleModalShow("Error", "There was an error exiting protocol for selected validators");
        }
        refreshData();
        setLoading(false);
    });
    useEffect(() => {
        const getExitProof = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { proof } = yield getProofArgs(address, "exits");
                if (proof.length > 0) {
                    setAllowedExit(proof[1]);
                    setProof(proof);
                }
            }
            catch (err) {
                console.log(err);
            }
        });
        getExitProof();
    }, []);
    return (React.createElement("div", { className: "tab-pane", id: "tabs-4", role: "tabpanel" },
        React.createElement("div", { className: "fullhegigth" },
            React.createElement("h2", null, "Registered Validators"),
            React.createElement("div", { className: "mcrow" },
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "text-center" }, "Validator Index"),
                            React.createElement("th", { className: "text-center" }, "Status"))),
                    React.createElement("tbody", null, validators.map((validator, key) => (React.createElement(React.Fragment, null, (validator.active || validator.exitRequested) &&
                        React.createElement("tr", { key: key },
                            React.createElement("td", { className: 'text-center' }, `${validator.index}`),
                            React.createElement("td", { className: 'text-center' }, (proof.length > 0 && proof[1].includes(validator.index)) ? (React.createElement("span", { className: `badge badge-info text-light` }, "Allowed Exit")) : (validator.exitRequested ? (React.createElement("span", { className: `badge badge-info text-light` }, "Exit Requested")) : (React.createElement(React.Fragment, null,
                                React.createElement("span", { className: `badge badge-info text-light` }, "Request Exit"),
                                React.createElement("form", null,
                                    React.createElement("input", { type: "checkbox", className: "validator-exit", value: validator.index }))))))))))))),
            validators.length > 0 && (loading ?
                (React.createElement("div", { className: "d-flex flex-row fixebtn justify-content-center" },
                    React.createElement(HashLoader, { color: '#bc519a', loading: loading, size: 50, "aria-label": "Loading Spinner", "data-testid": "loader" })))
                :
                    (React.createElement("div", { className: "fixebtn" },
                        React.createElement("a", { href: "#", className: "uniqbtn", style: { margin: '20px' }, onClick: reqExit }, "Request Exit"),
                        React.createElement("a", { href: "#", className: "uniqbtn", style: { margin: '20px' }, onClick: exit }, "Exit Pool"))))),
        React.createElement(Modal, { show: showModal, onHide: handleModalClose, centered: true },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, modalTitle)),
            React.createElement(Modal.Body, null, modalMessage),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { onClick: handleModalClose }, "Close")))));
};
export default Exit;
