var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useAccount, useSigner } from 'wagmi';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Register from './Register';
import Balance from './Balance';
import Claim from './Claim';
import Exit from './Exit';
import Pool from './Pool';
const Dashboard = () => {
    const { address } = useAccount();
    const { data: signer } = useSigner();
    const [loading, setLoading] = useState(true);
    const [validators, setValidators] = useState([]);
    const [regValidators, setRegValidators] = useState([]);
    const getValidators = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield fetch(`https://node-goerli.smoothly.money/validators/${address}`);
            const { data } = yield response.json();
            let registered = [];
            let unregistered = [];
            for (let validator of data) {
                if (typeof validator.rewards === 'undefined') {
                    unregistered.push(validator);
                }
                else {
                    registered.push(validator);
                }
            }
            setValidators(registered);
            setRegValidators(unregistered);
        }
        catch (err) {
            console.log(err);
        }
    });
    const refreshData = () => __awaiter(void 0, void 0, void 0, function* () {
        if (signer != null) {
            yield getValidators();
            setLoading(false);
        }
    });
    useEffect(() => {
        // Load Initial Validators
        refreshData();
        const updateDataInterval = setInterval(() => {
            refreshData();
        }, 10000);
        return () => clearInterval(updateDataInterval);
    }, [signer]);
    return (React.createElement("div", { id: "bgcolorchange", className: "d-flex flex-column justify-content-center" },
        React.createElement(Header, null),
        !loading &&
            React.createElement("div", { className: "tab-content maincontent" },
                React.createElement(Register, { refreshData: refreshData, registrants: regValidators, validators: validators }),
                React.createElement(Balance, { refreshData: refreshData, validators: validators }),
                React.createElement(Claim, { refreshData: refreshData, validators: validators }),
                React.createElement(Exit, { refreshData: refreshData, validators: validators }),
                React.createElement(Pool, null)),
        React.createElement(Footer, null)));
};
export default Dashboard;
