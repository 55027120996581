var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { utils } from 'ethers';
import { STAKE_FEE, MISS_FEE } from "../utils/constants";
import formatEthAmount from "../utils/formatEthAmount";
import React, { useState, useEffect } from 'react';
const Pool = () => {
    const [stats, setStats] = useState({
        awaiting_activation: 0,
        activated: 0,
        total_rewards: 0,
        total_stake: 0,
        total_value: 0,
        total_withdrawals: 0,
        total_value_period: 0,
        average_value: 0,
        total_miss: 0,
        total_fee: 0
    });
    const getPoolStats = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield fetch(`https://node-goerli.smoothly.money/poolstats`);
            const data = yield response.json();
            setStats(data);
        }
        catch (err) {
            console.log(err);
        }
    });
    useEffect(() => {
        getPoolStats();
    }, []);
    return (React.createElement("div", { className: "tab-pane", id: "tabs-5", role: "tabpanel" },
        React.createElement("h2", null, "Pool Stats"),
        React.createElement("div", { className: "mcrow rd1" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "text-center" }, "Activated Validators"),
                        React.createElement("th", { className: "text-center" }, "Validators Awaiting Activation"),
                        React.createElement("th", { className: "text-center" }, "Total Rewards"),
                        React.createElement("th", { className: "text-center" }, "Total Stake"),
                        React.createElement("th", { className: "text-center" }, "Total value"),
                        React.createElement("th", { className: "text-center" }, "Total value this period"),
                        React.createElement("th", { className: "text-center" }, "Average Value"))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { className: "text-center" }, stats.activated),
                        React.createElement("td", { className: "text-center" }, stats.awaiting_activation),
                        React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(stats.total_rewards))),
                        React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(stats.total_stake))),
                        React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(stats.total_value))),
                        React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(stats.total_value_period))),
                        React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(stats.average_value))))))),
        React.createElement("h2", { className: "margins2" }, "Pool Penalties"),
        React.createElement("div", { className: "mcrow rd1 margins1" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "text-center" }, "Fee Recipient Slashings"),
                        React.createElement("th", { className: "text-center" }, "Missed Proposal Slashings"),
                        React.createElement("th", { className: "text-center" }, "Total value from slashings"))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { className: "text-center" }, stats.total_fee),
                        React.createElement("td", { className: "text-center" }, stats.total_miss),
                        React.createElement("td", { className: "text-center" }, formatEthAmount(utils.formatEther(STAKE_FEE.mul(stats.total_fee).add(MISS_FEE.mul(stats.total_miss)))))))))));
};
export default Pool;
